import './App.css';
import { ThemeProvider } from "@mui/material";
import ComingSoon from './pages/coming-soon/ComingSoon';
import Landing from './pages/landing/Landing';
import OverridedThemes from './mui-themes/ThemeOverrides';
import { Route, Routes } from 'react-router-dom';
import { useState } from 'react';


function App() {
  const [isLandingPage, setIsLandingPage] = useState(true)
  return (
    <ThemeProvider theme={OverridedThemes}>
      {
        isLandingPage ?
          <Landing setIsLandingPage={setIsLandingPage} /> :
          <ComingSoon />
      }

      {/* <Routes> */}
      {/* <Route path="/" element={<Landing />} /> */}
      {/* <Route path="/coming-soon" element={<ComingSoon />} /> */}
      {/* </Routes> */}

    </ThemeProvider>
  );
}

export default App;
