import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import ImageAssets from "../../assets";
import { Theme, useMediaQuery } from "@mui/material";
import useEmblaCarousel, {
  EmblaCarouselType,
  EmblaOptionsType,
} from "embla-carousel-react";
import { useState, useCallback, useEffect } from "react";
import Autoplay from 'embla-carousel-autoplay'
import { useNavigate } from "react-router-dom";
import styles from './LandingPage.module.css'

type PlatformItemProps = {
  id: number;
  icon: string;
};

type CarouselItemProps = {
  id: number;
  src: string;
  content: string;
};

type BlogItemProps = {
  id: number;
  icon: string;
  name: string;
  description: string;
};

const carouselItems: CarouselItemProps[] = [
  {
    id: 1,
    src: ImageAssets.ic_music,
    content: "Visual Art",
  },
  {
    id: 2,
    src: ImageAssets.ic_dance,
    content: "Music",
  },
  {
    id: 3,
    src: ImageAssets.ic_designer,
    content: "Dance",
  },
  {
    id: 4,
    src: ImageAssets.ic_visual_art,
    content: "Designers",
  },
  {
    id: 5,
    src: ImageAssets.ic_fashion,
    content: "Fashion",
  },
];

const blogItems: BlogItemProps[] = [
  {
    id: 1,
    icon: ImageAssets.ic_create,
    name: "Create",
    description:
      "Upload your work and talent using photos, audio, video and an in-built text card tool to showcase your creativity to other artists worldwide on Sekeron",
  },
  {
    id: 2,
    icon: ImageAssets.ic_connect,
    name: "Connect",
    description:
      "Create projects, find the right artists, and collaborate smoothly using Collab Space with access to collaboration tools",
  },
  {
    id: 3,
    icon: ImageAssets.ic_collaborate,
    name: "Collaborate",
    description:
      "Upload your work and talent using photos, audio, video and an in-built text card tool to showcase your creativity to other artists worldwide on Sekeron",
  },
  {
    id: 4,
    icon: ImageAssets.ic_explore,
    name: "Explore",
    description:
      "Search or discover an extensive collection of artists, projects, and posts, thoughtfully tailored to suit your interests and creative preferences",
  },
  {
    id: 5,
    icon: ImageAssets.ic_manage,
    name: "Manage",
    description:
      "Streamline your project management with your own Dashboard that keeps your tasks, meetings, and deadlines organised at one place",
  },
  {
    id: 6,
    icon: ImageAssets.ic_inspire,
    name: "Inspire",
    description:
      "Inspire and be inspired within a global network of artists from all art forms, all ready to share, learn, and thrive together",
  },
];


const OPTIONS: EmblaOptionsType = {
  align: "start",
  // dragFree: true,
  // loop: true,
  startIndex: 1,
  slidesToScroll: 1,
  // breakpoints: { "(max-width: 768px)": { startIndex: 1 } },
};

const BLOG_OPTIONS: EmblaOptionsType = {
  breakpoints: {
    "(max-width: 768px)": {
      startIndex: 1,
      align: "start",
      // dragFree: true,
      slidesToScroll: 1,
      // loop: true,
    },
  },
};

const QRCode = () => (
  <Avatar
    sx={{ width: 20, height: 20 }}
    src={ImageAssets.ic_qr_code}
    alt="qr-code"
  />
);

const Item = (props: Omit<CarouselItemProps, "id">) => {
  const hidden = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const { src, content } = props;
  return (
    <Avatar
      className="category-card"
      sx={{ objectFit: "contain", height: hidden ? "42rem" : "40rem", width: hidden ? "30rem" : "29rem" }}
      src={src}
      alt={content}
    />
  );
};

const BlogItem = (props: Omit<BlogItemProps, "id">) => {
  const { icon, name } = props;

  return (
    <Avatar
      style={{ width: "100%", height: "100%" }}
      src={icon}
      alt={name}
      imgProps={{
        sx: {
          objectFit: "contain",
        },
      }}
    />
  );
};

const autoplayOptions = {
  delay: 4000,
  rootNode: (emblaRoot: any) => emblaRoot.parentElement,
  stopOnInteraction: true
}

const Landing = ({ setIsLandingPage }: any) => {
  const navigate = useNavigate()
  // const [emblaRef] = useEmblaCarousel(OPTIONS, [Autoplay(autoplayOptions)]);
  const [emblaRef] = useEmblaCarousel(OPTIONS);
  const [blogEmblaRef, emblaApi] = useEmblaCarousel(BLOG_OPTIONS);
  const hidden = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const [selectedIndex, setSelectedIndex] = useState(0);

  const scrollTo = useCallback(
    (index: number) => emblaApi && emblaApi.scrollTo(index),
    [emblaApi]
  );

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    onSelect(emblaApi);
    emblaApi.on("reInit", onSelect);
    emblaApi.on("select", onSelect);
  }, [emblaApi, onSelect]);

  const handleNavigate = () => {
    setIsLandingPage(false)
  }

  return (
    <>
      <div className={styles["mobile-container"]}>
        <img
          className={styles["sekeron-logo"]}
          src={ImageAssets.ic_header_logo}
          alt="header-logo"
        />

        <div className={styles["mobile-sub-container"]}>

          <div className={styles["image-container"]}>
            <img
              alt="hero section vector"
              src={ImageAssets.ic_hero_section_vector} />
          </div>

          <Stack
            direction={"column"}
            alignItems={"center"}
            rowGap={"5rem"}
          >
            <Stack
              direction={"column"}
              alignItems={"center"}
            >
              <Typography className={styles["be-the-artist-text"]}>{"Be the "}<span>ARTIST</span></Typography>
              <Typography className={styles["be-the-artist-text"]}>
                {"you "}
                <span>{"WANT "}</span>
                {"to "}
                <span>{"BE "}</span>
              </Typography>
            </Stack>

            <Button className={styles["explore-sekeron"]}
              onClick={handleNavigate}
            >
              Explore Sekeron
            </Button>
          </Stack>

          <Stack className={styles["categories-container"]}>
            <Typography className={styles["category-section-title"]}>
              <span>All artists</span>&nbsp;
              {"find a home here"}
            </Typography>
            <Box ref={emblaRef} sx={{ overflowX: "hidden" }}>
              <Stack style={{
                columnGap: "4rem",
                width: "95%",
                margin: "auto",
              }}>
                {carouselItems.map(({ id, ...item }: CarouselItemProps) => (
                  <Item key={id} {...item} />
                ))}
              </Stack>
            </Box>
          </Stack>

          <Stack className={styles["blog-section-container"]}>
            <Typography className={styles["blog-section-title"]} variant={"h1"}>
              What&apos;s in it for <span>you</span>
            </Typography>

            <Box ref={blogEmblaRef} sx={{ overflowX: "hidden", alignItems: "center" }}>
              <Grid
                component={Stack}
                container={!hidden}
                gap={3}
                justifyContent={"flex-start"}
              >
                {blogItems.map(({ id, ...item }: BlogItemProps) => (
                  <Grid
                    className={styles["blog-item"]}
                    // item={!hidden}
                    key={id}
                    xs={6}
                    sm={6}
                  >
                    <BlogItem {...item} />
                  </Grid>
                ))}
              </Grid>
            </Box>

            <Stack justifyContent={"center"} columnGap={"0.6rem"}>
              {blogItems.map((_, index) => (
                <Button
                  onClick={() => scrollTo(index)}
                  className={index === selectedIndex ? styles[`embla-thumbs-selected`] : styles[`embla-thumbs`]}
                ></Button>
              ))}
            </Stack>
          </Stack>

          <Stack className={styles["mobile-screen-container"]}>
            <Stack className={styles["blog-section-sub-container"]}>
              <Stack className={styles["blog-section-sub-title"]}>
                <Typography >
                  <span>{"One "}</span>
                  Platform
                </Typography>
                <Typography>
                  {"Endless "}
                  <span>{"Possibilities"}</span>&nbsp;
                </Typography>
              </Stack>
              <Stack direction={"column"} alignItems={"center"}>
                <Typography className={styles["blog-section-description"]}>
                  {"Sekeron app is available on both "}
                </Typography>
                <Typography className={styles["blog-section-description"]}>{"Android and IOS"}</Typography>
              </Stack>

            </Stack>
            <div className={styles["mobile-home-screen"]}>
              <img src={ImageAssets.ic_i_phone_12_pro} />
            </div>
          </Stack>

          <Stack className={styles["web-screen-container"]}>
            <Stack
              direction={"column"}
              justifyContent={"center"}
              rowGap={"7rem"}
              alignItems={"center"}
            >
              <Stack direction={"column"} rowGap={"2rem"}>
                <Typography className={styles["section-title"]}>
                  {"Create, Connect "}
                </Typography>
                <Typography className={styles["section-title"]}>
                  {"& Collaborate"}
                </Typography>
              </Stack>

              <Button className={styles["explore-now"]}>
                {"Explore Now"}
              </Button>
            </Stack>
            <Stack
              justifyContent={"flex-end"}
              alignItems={"end"}
              sx={{
                width: "90%",
                marginLeft: "auto"
              }}
            >
              <img className={styles["mobile-desktop-screen"]} src={ImageAssets.ic_web_home_screen} />
            </Stack>
          </Stack>

        </div>

        <Stack
          width={"100%"}
          justifyContent={"center"}
          padding={"2rem 0rem"}
          direction={"row"}
          columnGap={"2rem"}
        >
          <Button
            variant="outlined"
            onClick={handleNavigate}
          >
            Get Sekeron App
          </Button>
          <Button variant="contained" color="primary"
            onClick={handleNavigate}
          >
            Sign Up / Login
          </Button>
        </Stack>

      </div>

      <Stack
        className={styles["container"]}
      // heroSectionBg={ImageAssets.ic_grid_bg}
      >

        <Stack className={styles["header-container"]}>
          <img
            className={styles["sekeron-logo"]}
            src={ImageAssets.ic_header_logo}
            alt="header-logo"
          />
          <Stack
            bgcolor={"#000"}
            // width={hidden ? "100%" : "auto"}
            // justifyContent={"center"}
            // padding={hidden ? "2rem 0.5rem" : "0"}
            // position={hidden ? "fixed" : "relative"}
            bottom={0}
            zIndex={3}
            columnGap={4}
          >
            <Button
              variant="outlined"
              startIcon={hidden ? undefined : <QRCode />}
              onClick={handleNavigate}
            >
              Get Sekeron App
            </Button>
            <Button variant="contained" color="primary"
              onClick={handleNavigate}
            >
              Sign Up / Login
            </Button>
          </Stack>
        </Stack>

        <Stack className={styles["group-image-container"]}>
          <Stack>
            <div className={styles["image-container"]}>
              <img
                alt="hero section vector"
                src={ImageAssets.ic_hero_section_vector} />
            </div>

            <Stack>
              <Typography className={styles["be-the-artist-text"]}>{"Be the "}<span>ARTIST</span></Typography>

              <Typography className={styles["be-the-artist-text"]}>
                {"you "}
                <span>WANT</span>
                <Typography className={styles["be-the-artist-text"]}>
                  {"to "}
                  <span>BE</span>
                </Typography>
              </Typography>

              <div className={styles["button-container"]}>
                <img src={ImageAssets.ic_leaf} alt="Button Image" />
                <Button className={styles["explore-sekeron"]}
                  onClick={handleNavigate}
                >
                  Explore Sekeron
                </Button>
              </div>
            </Stack>
          </Stack>
        </Stack>

        <Stack className={styles["categories-container"]}>
          <Typography className={styles["category-section-title"]}>
            <span>All artists</span>&nbsp;
            {"find a home here"}
          </Typography>

          <Stack style={{ columnGap: "7rem", overflow: "auto", margin: "0px 5rem", alignItems: "center" }}>
            {carouselItems.map(({ id, ...item }: CarouselItemProps) => (
              <Item key={id} {...item} />
            ))}
          </Stack>
        </Stack>


        <Stack className={styles["blog-section-container"]}>
          <Typography className={styles["blog-section-title"]} variant={"h1"}>
            What&apos;s in it for <span>you</span>
          </Typography>

          <Grid
            container
            sx={{
              gap: {
                xs: 3,
                xl: 8
              }
            }}
            justifyContent={hidden ? "flex-start" : "center"}
          >
            {blogItems.map(({ id, ...item }: BlogItemProps) => (
              <Grid
                className="blog-item"
                item
                key={id}
                xs={7}
                sm={7}
                md={3}
                lg={3}
                xl={3}
              >
                <BlogItem {...item} />
              </Grid>
            ))}
          </Grid>
        </Stack>

        <Stack className={styles["mobile-screen-container"]}>
          <img src={ImageAssets.ic_mobile_screen} />
          <Stack className={styles["blog-section-sub-container"]}>
            <Stack className={styles["blog-section-sub-title"]}>
              <Typography >
                <span>{"One "}</span>
                Platform
              </Typography>
              <Typography>
                {"Endless "}
                <span>{"Possibilities"}</span>&nbsp;
              </Typography>
            </Stack>
            <Typography className={styles["blog-section-description"]}>
              {"Sekeron app is available on both Android and IOS"}
            </Typography>

            <Stack className={styles["playstore-container"]}>
              <img src={ImageAssets.ic_google_play} />
              <img src={ImageAssets.ic_app_store} />
            </Stack>

          </Stack>
        </Stack>

        <Stack className={styles["web-screen-container"]}>
          <Stack
            direction={"column"}
            justifyContent={"center"}
            rowGap={"5rem"}
          >
            <Stack direction={"column"} rowGap={"2rem"}>
              <Typography className={styles["section-title"]}>
                {"Create, "}
              </Typography>

              <Typography className={styles["section-title"]}>
                {"Connect "}
              </Typography>
              <Typography className={styles["section-title"]}>
                {"& Collaborate"}
              </Typography>
            </Stack>

            <Button onClick={handleNavigate} className={styles["explore-now"]}>
              {hidden ? "Explore Now" : "Start Now"}
            </Button>
          </Stack>
          <img src={ImageAssets.ic_web_screen} />
        </Stack>

      </Stack>

    </>
  );
};

export default Landing;
