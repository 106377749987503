
import gif_sekeron_logo from "./images/gif_sekeron_logo.gif";
import ic_sekeron from "./images/ic_sekeron.png";
import ic_grid from "./images/ic_grid.svg";
import ic_coming_soon_grid from "./images/ic_coming_soon_grid.svg";

// Landing page related images
import ic_header_logo from "./landing-page-images/header-logo.webp";
import ic_grid_bg from "./landing-page-images/grid.svg";
import ic_original_sekeron_logo from "./images/ic_original_sekeron_logo.svg";

import ic_i_phone_12_pro from "./landing-page-images/i-phone-12-pro@3x.webp";
// import ic_macbook_pro_mockup from "./landing-page-images/macbook-pro-mockup.webp";
// import sekeron_bg_logo from "./landing-page-images/sekeron_bg_logo.webp";
import ic_qr_code from "./landing-page-images/QR-code.webp";
import ic_hero_section_vector from "./landing-page-images/hero-section-vector.webp";
// import ic_exposure_1 from "./landing-page-images/our-exposure-images/exposure-1.webp";
// import ic_exposure_2 from "./landing-page-images/our-exposure-images/exposure-2.svg";
// import ic_exposure_3 from "./landing-page-images/our-exposure-images/exposure-3.svg";
// import ic_exposure_4 from "./landing-page-images/our-exposure-images/exposure-4.svg";
// import ic_exposure_5 from "./landing-page-images/our-exposure-images/exposure-5.svg";
// import ic_platform_1 from "./landing-page-images/platform-images/group-55@3x.webp";
// import ic_platform_2 from "./landing-page-images/platform-images/group-54@3x.webp";
// import ic_platform_3 from "./landing-page-images/platform-images/group-53@3x.webp";
// import ic_platform_4 from "./landing-page-images/platform-images/group-52@3x.webp";
// import ic_platform_5 from "./landing-page-images/platform-images/group-51@3x.webp";
// import ic_platform_6 from "./landing-page-images/platform-images/group-50@3x.webp";
// import ic_platform_7 from "./landing-page-images/platform-images/group-49@3x.webp";
import ic_app_store from "./landing-page-images/ic_app_store.svg";
import ic_google_play from "./landing-page-images/ic_google_play.webp";

import ic_create from "./landing-page-images/ic_create.png";
import ic_connect from "./landing-page-images/ic_connect.png";
import ic_collaborate from "./landing-page-images/ic_collaborate.png";
import ic_explore from "./landing-page-images/ic_explore.png";
import ic_manage from "./landing-page-images/ic_manage.png";
import ic_inspire from "./landing-page-images/ic_inspire.png";

import ic_designer from "./landing-page-images/ic_designer.webp";
import ic_dance from "./landing-page-images/ic_dance.webp";
import ic_visual_art from "./landing-page-images/ic_visual_art.webp";
import ic_fashion from "./landing-page-images/ic_fashion.png";
import ic_music from "./landing-page-images/ic_music.png";
import ic_leaf from "./images/ic_leaf.svg";
import ic_web_screen from "./images/ic_web_screen.svg";
import ic_mobile_screen from "./images/ic_mobile_screen.svg";
import ic_web_home_screen from "./images/ic_web_home_screen.svg";

const ImageAssets = {
  ic_sekeron,
  gif_sekeron_logo,
  ic_header_logo,
  ic_grid_bg,
  ic_qr_code,
  ic_i_phone_12_pro,
  // ic_macbook_pro_mockup,
  // sekeron_bg_logo,
  ic_hero_section_vector,
  // ic_exposure_1,
  // ic_exposure_2,
  // ic_exposure_3,
  // ic_exposure_4,
  // ic_exposure_5,
  ic_create,
  ic_connect,
  ic_manage,
  ic_explore,
  ic_collaborate,
  ic_inspire,
  // ic_platform_1,
  // ic_platform_2,
  // ic_platform_3,
  // ic_platform_4,
  // ic_platform_5,
  // ic_platform_6,
  // ic_platform_7,
  ic_app_store,
  ic_google_play,
  ic_dance,
  ic_designer,
  ic_visual_art,
  ic_music,
  ic_fashion,
  ic_original_sekeron_logo,
  ic_leaf,
  ic_mobile_screen,
  ic_web_screen,
  ic_web_home_screen,
  ic_grid,
  ic_coming_soon_grid
};

export default ImageAssets;
